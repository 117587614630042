import type {UseAxiosOptions} from "@vueuse/integrations/useAxios";
import {useAuthService} from "@/frontend/entities/auth";
import {useAuthStore} from "@/frontend/entities/auth";
import type {AxiosError} from "axios";
import type {TServerResponse} from "@/frontend/entities/app";

export const useAxiosOptions: UseAxiosOptions = {
    immediate: false,
    onError(error: any) {

        console.log('error', error);

        if (error?.response?.status === 401) {
            const AuthService = useAuthService();
            const AuthStore = useAuthStore();
            if (AuthStore.isAuth) {
                AuthService.logout();
                location.href = '/login';
            }
        }
        /*toast({
            title: 'Error',
            description: ((error as AxiosError)?.response?.data as TServerResponse)?.message ?? (error as AxiosError)?.message,
            variant: 'destructive'
        })*/

        // alert(((error as AxiosError)?.response?.data as TServerResponse)?.message ?? (error as AxiosError)?.message);
    },
}
