import type {TSelectOption} from "@/frontend/shared/ui/data-table";
import {ECompanyMode, ECompanyPaymentSystem, ECompanyStatus} from "@/frontend/entities/company";
import type {TEntityMedia} from "@/frontend/entities/app";
import {ESubscriptionTariffStatus, ESubscriptionTariffType} from "@/frontend/entities/subscription";
import type {TSubscriptionTariffOption} from "@/frontend/entities/subscription/tariff/model/types";

export type TViewer = {
    id: number;
    email: string;
    legalEntity: string | null;
    phone: string | null;
    profile: TViewerProfile | null;
    hardware_limit: number | null;
    roles: Array<any>;
    permissions: Array<any>;
    paymentSystem: ECompanyPaymentSystem | null;
    subscription_tariff: TViewerSubscriptionTariff | null;
    recurrentID: string | null;
    balance: number | null;
    mode: ECompanyMode;
    nextPaymentDate: string | null;
    demoBlockedAt: string | null;
    demoExtension: string | null;
    referralCode: string;
    completedRegistration: boolean;
    ready_share_clients: boolean;
    publish_new_reviews_without_moderation: boolean;
    access_token: string | null;
    isImpersonated: boolean;
    status: ECompanyStatus;
    created_at: string;
};

export type TViewerProfile = {
    id: number;
    name: string | null;
    avatar: TEntityMedia | null;
    email: string | null;
    phone: string | null;
    created_at: string;
};

export type TViewerSubscriptionTariff = {
    id: number;
    code: string;
    type: ESubscriptionTariffType;
    name: string;
    technical_name: string | null;
    description: string | null;
    amount: number;
    subscription_period: string | null;
    options: TSubscriptionTariffOption[];
    params: any;
    status: ESubscriptionTariffStatus;
};

export type TUser = {
    id: number;
    name: string;
    email: string;
    last_enter_date: string | null;
    status: EUserStatus;
    hardware_limit: number;
    roles: EUserRole[];
    permissions: string[];
    created_at: string;
    updated_at: string | null;
};

export enum EUserRole {
    ADMIN = 'admin',
    CLIENT = 'client',
    MANAGER = 'manager',
    GUEST = 'guest'
}

export const VUserRole = {
    'admin': 'Admin',
    'client': 'Client',
    'manager': 'Manager',
    'guest': 'Guest',
} as const;

export const OUserRole: TSelectOption[] = [
    {label: VUserRole[EUserRole.ADMIN], value: EUserRole.ADMIN},
    {label: VUserRole[EUserRole.CLIENT], value: EUserRole.CLIENT},
    {label: VUserRole[EUserRole.MANAGER], value: EUserRole.MANAGER},
    {label: VUserRole[EUserRole.GUEST], value: EUserRole.GUEST},
] as const;

export enum EUserStatus {
    DISABLED = 0,
    ENABLED = 1
}

export const VUserStatus = {
    0: 'Disabled',
    1: 'Enabled',
} as const;

export const OUserStatus: TSelectOption[] = [
    {label: VUserStatus[EUserStatus.DISABLED], value: EUserStatus.DISABLED},
    {label: VUserStatus[EUserStatus.ENABLED], value: EUserStatus.ENABLED},
] as const;
