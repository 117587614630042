import type {TSelectOption} from "@/frontend/shared/ui/form/select";

export type TSubscriptionTariff = {
    code: string;
    name: string | null;
    category: string;
    amount: number;
    stream_amount: number;
    activation_limit: number;
    activation_amount: number;
    quantity_limit: number | null;
    unlimited_activations_amount: number | null;
    days_for_edit: number | null;
    renewal_percent: number | null;
};

export type TSubscriptionTariffOption = {
    code: string;
    name: string | null;
    value: string | number | boolean;
};

export enum ESubscriptionTariffOptionCode {
    BATCH_DOWNLOAD = 'batch-download',
    ALPHA_CHANNEL_MASKS = 'alpha-channel-masks',
    GET_DELETE_REFUND = 'get-delete-refund',
    METAS_DISABLE_TOGGLE = 'metas-disable-toggle',
    THREE_D_DOWNLOAD = 'three-d-download',
    QR_ON_PHOTO = 'qr-on-photo',
    CALENDAR = 'calendar',
    VISIT_CARD = 'visit-card',
    LEAFLET = 'leaflet',
    VISIT_CARD_FORMAT = 'visit-card-format',
    PHOTOBOOK_BUTTONS = 'photobook-buttons',
    PHOTOBOOK_EFFECTS = 'photobook-effects',
    SELF_LIVING = 'self-living',
    PROFILE_PRODUCTS = 'profile-products',
    PROFILE_ORDERS = 'profile-orders',
    PROFILE_PUSH_NOTIFICATIONS = 'profile-push-notifications',
    PROFILE_AD_NOTIFICATIONS = 'profile-ad-notifications',
    STREAMING_VIDEO = 'streaming-video',
};


export enum ESubscriptionTariffType {
    DEMO = 3,
    LICENSE = 1,
    SUBSCRIPTION = 2,
}

export const VSubscriptionTariffType = {
    3: 'Demo',
    1: 'License',
    2: 'Subscription',
} as const;

export const OSubscriptionTariffType: TSelectOption[] = [
    {label: VSubscriptionTariffType[ESubscriptionTariffType.DEMO], value: ESubscriptionTariffType.DEMO},
    {label: VSubscriptionTariffType[ESubscriptionTariffType.LICENSE], value: ESubscriptionTariffType.LICENSE},
    {label: VSubscriptionTariffType[ESubscriptionTariffType.SUBSCRIPTION], value: ESubscriptionTariffType.SUBSCRIPTION},
] as const;

export enum ESubscriptionTariffStatus {
    DISABLED = 0,
    ENABLED = 1,
    ARCHIVED = 5,
    TO_BE_REMOVED = 9,
}

export const VSubscriptionTariffStatus = {
    0: 'Disabled',
    1: 'Enabled',
    5: 'Archived',
    9: 'To be removed',
} as const;

export const OSubscriptionTariffStatus: TSelectOption[] = [
    {label: VSubscriptionTariffStatus[ESubscriptionTariffStatus.DISABLED], value: ESubscriptionTariffStatus.DISABLED},
    {label: VSubscriptionTariffStatus[ESubscriptionTariffStatus.ENABLED], value: ESubscriptionTariffStatus.ENABLED},
    {label: VSubscriptionTariffStatus[ESubscriptionTariffStatus.ARCHIVED], value: ESubscriptionTariffStatus.ARCHIVED},
    {label: VSubscriptionTariffStatus[ESubscriptionTariffStatus.TO_BE_REMOVED], value: ESubscriptionTariffStatus.TO_BE_REMOVED},
] as const;
