import type {TAuthHeader} from "../";
import {useAuthStore} from "../";
import {useLocaleStore} from "@/frontend/entities/locale";

export function authHeader(): TAuthHeader {
    let ojvToken = localStorage.getItem('ojv-token');
    const {$locale} = useLocaleStore();

    if (ojvToken) {
        return {
            Authorization: 'Bearer ' + ojvToken,
            Locale: $locale
        };
    } else {
        return {};
    }
}

export function onError() {

}

export function authHandlerHeader(): TAuthHeader {
    const AuthStore = useAuthStore();

    if (AuthStore?.user.access_token) {
        return {Authorization: 'Bearer ' + AuthStore.user.access_token};
    } else {
        return {};
    }
}
