import {computed, type Ref, ref} from 'vue';
import {acceptHMRUpdate, defineStore} from 'pinia'
import {EUserRole, type TViewer} from "@/frontend/entities/user";
import {ECompanyMode} from "@/frontend/entities/company";
import dayjs from "dayjs";
import {ESubscriptionTariffType} from "@/frontend/entities/subscription";
import {useRouter} from "vue-router";

const defaultViewer: TViewer = {} as TViewer;

export const useAuthStore = defineStore('auth', () => {
    let user: Ref<TViewer> = ref(defaultViewer);
    let isAppInitialized: Ref<boolean> = ref(false);
    let isDemoMode: Ref<boolean> = ref(false);
    let isLockedMode: Ref<boolean> = ref(false);
    const isAuth: Ref<boolean> = ref(false);
    const router = useRouter();

    const serverUnixTime: string | undefined = document.querySelector('meta[name="serverUnixTime"]')?.getAttribute('content') || undefined;

    const serverOffset: Ref<number> = computed(() => {
        return serverUnixTime ? dayjs(parseInt(serverUnixTime) * 1000).diff(dayjs()) : 0;
    });

    const isSoonSubscribeLocked: Ref<boolean> = computed(() => {
        return !user.value.recurrentID && !!user.value.nextPaymentDate && dayjs(user.value.nextPaymentDate).diff(dayjs().add(7, 'days')) < 0;
    });

    const isSubscriptionPaymentType: Ref<boolean> = computed(() => {
        return user.value.subscription_tariff?.type === ESubscriptionTariffType.SUBSCRIPTION;
    });

    const isLicensePaymentType: Ref<boolean> = computed(() => {
        return user.value.subscription_tariff?.type === ESubscriptionTariffType.LICENSE;
    });

    const demoBlockedTime: Ref<number | null> = computed(() => {
        // console.log('asdas', dayjs('2024-08-20T17:12:12+03:00').diff(dayjs()));
        return user.value.demoBlockedAt ? dayjs(user.value.demoBlockedAt).unix() : null;
    });

    const subscribeBlockingTime: Ref<number | null> = computed(() => {
        // console.log('asdas', dayjs('2024-08-20T17:12:12+03:00').diff(dayjs()));
        return user.value.nextPaymentDate ? dayjs(user.value.nextPaymentDate).unix() : null;
    });

    const isDemoEnded: Ref<boolean> = computed(() => {
        return isDemoMode.value && !!demoBlockedTime.value && demoBlockedTime.value < dayjs().add(serverOffset.value, 'ms').unix();
    });

    const blockingTime = computed(() => {
        if (isDemoMode.value && demoBlockedTime.value) {
            return demoBlockedTime.value;
        } else if (isSubscriptionPaymentType.value && subscribeBlockingTime.value) {
            return subscribeBlockingTime.value;
        }

        return null;
    })

    const blockingDate = computed(() => {
        if (isDemoMode.value && user.value.demoBlockedAt) {
            return dayjs(user.value.demoBlockedAt).format('DD.MM.YYYY');
        } else if (isSubscriptionPaymentType.value && user.value.nextPaymentDate) {
            return dayjs(user.value.nextPaymentDate).format('DD.MM.YYYY');
        }

        return null;
    })

    const setUser = async (payload: TViewer, token?: string): Promise<void> => {
        if (token) {
            localStorage.setItem('ojv-token', token);
        }
        user.value = payload;
        isDemoMode.value = user.value.subscription_tariff?.type === ESubscriptionTariffType.DEMO;
        isLockedMode.value = user.value.mode === ECompanyMode.LOCKED;
        isAuth.value = true;
    }

    const userHasRole = (roles: EUserRole[] | EUserRole): boolean => {
        if (typeof roles === "string") {
            roles = [roles];
        }

        let result = false;
        if (!user.value?.roles) {
            return result;
        }

        for (const role of roles) {
            if (user.value.roles.includes(role)) {
                result = true;
            }
        }

        return result;
    }

    const userHasPermission = (permissions: string[] | string): boolean => {
        if (typeof permissions === "string") {
            permissions = [permissions];
        }

        let result = false;
        if (!user.value?.permissions) {
            return result;
        }

        for (const permission in permissions) {
            if (user.value.permissions.includes(permission)) {
                result = true;
            }
        }

        return true;
    }

    const userSubscriptionHasOption = (code: string): boolean => {
        let result = false;
        if (!user.value?.subscription_tariff?.options) {
            return result;
        }

        const option = user.value.subscription_tariff.options.find(item => item.code === code);
        if (option) {
            result = option.value as boolean;
        }

        return result;
    }

    const unsetUser = async (): Promise<boolean> => {
        localStorage.removeItem('ojv-token');
        isAuth.value = false;
        await router.push({name: 'auth.login'});
        user.value = defaultViewer;
        return true;
    }

    return {
        user,
        isAuth,
        setUser,
        userHasRole,
        userHasPermission,
        unsetUser,
        userSubscriptionHasOption,
        isDemoMode,
        isAppInitialized,
        isLockedMode,
        isSoonSubscribeLocked,
        isSubscriptionPaymentType,
        isLicensePaymentType,
        demoBlockedTime,
        serverOffset,
        isDemoEnded,
        blockingTime,
        blockingDate,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
