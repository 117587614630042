import {type SubmissionHandler, useForm} from 'vee-validate';
import * as Yup from 'yup';
import type {TForgotPassword} from "../model/types";
import useAuthService from "../api/service";
import {useMutation} from "@tanstack/vue-query";
import type {AxiosError} from "axios";
import type {TServerResponse} from "@/frontend/entities/app";
import {ref, type Ref} from "vue";

const schema = Yup.object({
    email: Yup.string().email('Please enter valid email').required('The email field is required'),
});

export const useForgotPasswordForm = () => {
    const AuthService = useAuthService();
    const form: Ref<TForgotPassword> = ref({
        email: '',
    });
    const isPasswordSent: Ref<boolean> = ref(false);

    const {errors, setErrors, defineField, handleSubmit} = useForm<TForgotPassword>({
        initialValues: form.value,
        validationSchema: schema,
    });

    const {mutate, isPending} = useMutation({
        mutationFn: (payload: TForgotPassword) => AuthService.passwordRecovery(payload),
        onSuccess(data, variables, context) {
            isPasswordSent.value = true;
        },
        onError(error: AxiosError, variables, context) {
            setErrors((error?.response?.data as TServerResponse)?.errors as any);
        },
    });

    const submit: SubmissionHandler = handleSubmit((values) => {
        mutate(values);
    }) as unknown as SubmissionHandler;

    return {form, errors, isPending, schema, isPasswordSent, submit}
};
