import type {TSelectOption} from "@/frontend/shared/ui/data-table";

export type TCompanyUpdate = {
    legalEntity?: string;
    phone?: string;
    email?: string;
};

export type TCompanyChangePassword = {
    old_password: string;
    password: string;
    password_confirmation: string;
};

export type TCompanyUpBalance = {
    amount?: number;
    paymentSystem: ECompanyPaymentSystem;
};

export type TCompanyChangeTariff = {
    tariff_id?: number;
    paymentSystem: ECompanyPaymentSystem;
};

export type TPersonalAccount = {
    id: number;
    userId: number | null;
    companyId: number;
    contract_amount: number;
    prepayment: number;
    balance: number;
    photo_score: number;
    photo: number;
    calendar: number;
    card: number;
    leaflet: number;
    locked: boolean;
    created_at: string;
    updated_at: string | null;
}

export type TCompanyStatistic = {
    id: number;
    activations_count: number;
    activations_new_count: number;
    clients_count: number;
    clients_new_count: number;
    views_contacts_count: number;
    views_contacts_new_count: number;
    views_count: number;
    views_new_count: number;
    orders_new_count: number;
    reviews_new_count: number;
}

export type TCompanyContactUs = {
    name: string;
    phone: string;
    subject: string | TSelectOption;
    communication: string | TSelectOption;
    message: string;
    link: string;
}

export type TPersonalAccountBalance = {
    balance: number;
}

export enum ECompanyPaymentType {
    DEMO = 'demo',
    DEFAULT = 'default',
    SUBSCRIPTION = 'subscription',
}

export const VCompanyPaymentType = {
    'demo': 'Demo',
    'default': 'License',
    'subscription': 'Subscription',
} as const;

export const OCompanyPaymentType: TSelectOption[] = [
    {label: VCompanyPaymentType[ECompanyPaymentType.DEMO], value: ECompanyPaymentType.DEMO},
    {label: VCompanyPaymentType[ECompanyPaymentType.DEFAULT], value: ECompanyPaymentType.DEFAULT},
    {label: VCompanyPaymentType[ECompanyPaymentType.SUBSCRIPTION], value: ECompanyPaymentType.SUBSCRIPTION},
] as const;

export enum ECompanyPaymentSystem {
    CLOUDPAYMENTS = 'cloudpayments',
    WOOPPAY = 'wooppay',
    ROBOKASSA = 'robokassa',
}

export const VCompanyPaymentSystem = {
    'cloudpayments': 'Cloudpayments',
    'wooppay': 'Wooppay',
    'robokassa': 'Robokassa',
} as const;

export enum ECompanyCloud {
    YANDEX = 0,
    AMAZON = 1
}

export enum ECompanyMode {
    WORK = 1,
    LOCKED = 2
}

export const VCompanyMode = {
    1: 'Work',
    2: 'Locked',
} as const;

export const OCompanyMode: TSelectOption[] = [
    {label: VCompanyMode[ECompanyMode.WORK], value: ECompanyMode.WORK},
    {label: VCompanyMode[ECompanyMode.LOCKED], value: ECompanyMode.LOCKED},
] as const;

export enum EContactUsSubject {
    BUY_LICENSE = 'buy_license',
    TARIFF_CONSULT = 'tariff_consult',
    DEMONSTRATION = 'demonstration'
}

export const VContactUsSubject = {
    'buy_license': 'Buying a license',
    'tariff_consult': 'Subscription advice',
    'demonstration': 'Demonstration of capabilities',
} as const;

export const OContactUsSubject: TSelectOption[] = [
    {label: VContactUsSubject[EContactUsSubject.BUY_LICENSE], value: EContactUsSubject.BUY_LICENSE},
    {label: VContactUsSubject[EContactUsSubject.TARIFF_CONSULT], value: EContactUsSubject.TARIFF_CONSULT},
    {label: VContactUsSubject[EContactUsSubject.DEMONSTRATION], value: EContactUsSubject.DEMONSTRATION},
] as const;

export enum EContactUsCommunicationType {
    CALL = 'call',
    TELEGRAM = 'telegram',
    WHATSAPP = 'whatsapp'
}

export const VContactUsCommunicationType = {
    'call': 'Call',
    'telegram': 'Telegram',
    'whatsapp': 'WhatsApp',
} as const;

export const OContactUsCommunicationType: TSelectOption[] = [
    {label: VContactUsCommunicationType[EContactUsCommunicationType.CALL], value: EContactUsCommunicationType.CALL},
    {label: VContactUsCommunicationType[EContactUsCommunicationType.TELEGRAM], value: EContactUsCommunicationType.TELEGRAM},
    {label: VContactUsCommunicationType[EContactUsCommunicationType.WHATSAPP], value: EContactUsCommunicationType.WHATSAPP},
] as const;

export enum ECompanyStatus {
    DISABLED = 0,
    ENABLED = 1
}
